import React, { Suspense, lazy } from "react";
import Loader from "../elements/Loader/Loader";
import Default from "./Default";

const Zone = lazy(() => import("../elements/Video/Zone"));

const VSCode = () => {
	return (
		<Default heading="ICECON 2019 - One of Raul's ADAC Projects" programName="ADAC-Icecon">
			<Suspense fallback={<Loader />}>
				<Zone />
			</Suspense>
		</Default>
	);
};

export default VSCode;
